


























import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import { generalDataService } from '@/services/GeneralDataService';
import { customQueryTesterService } from '@/services/CustomQueryTesterService';

@Component({
  components: { Header },
})
export default class CustomQueryTest extends Vue {
  data: any;
  params: any = [];
  type = '';
  result = 'Wynik...';
  mounted() {
    this.type = this.$route.params.type || '';
    generalDataService
      .getItem(parseInt(this.type, 10), 'generalList/customQuery')
      .then((data: any) => {
        this.data = data;
        this.params = [];
        data.params.split(',').map((item: string) => {
          if (item) {
            this.params.push({ name: item.trim(), model: '' });
          }
        });
      })
      .catch(() => {});
  }
  testQuery() {
    const data: any = {};
    this.params.map((item: any) => {
      data[item.name] = item.model;
    });
    customQueryTesterService.test(this.type, data).then((data: any) => {
      this.result = JSON.stringify(data, null, 4);
    });
  }
}
